// src/i18n.js
import { createI18n } from 'vue-i18n';
import ru from './locales/ru.js';
import en from './locales/en.js';

const i18n = createI18n({
    locale: localStorage.getItem('lang') || 'ru',
    messages: {
        ru,
        en,
    },
});

export default i18n;