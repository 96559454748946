import UserService from '../services/user.service';
import moment from "moment";
import i18n from '../i18n';

const chatbotId = localStorage.getItem('chatbotId');
i18n.global.locale = localStorage.getItem('lang') || 'ru';

export const data = {
    namespaced: true,
    state: {
        chatbotId,
        dialogueId: null,
        enableSound: false,
        messages: []
    },
    actions: {
        getMessages({ commit }, dialogueId) {
            return UserService.getMessages(dialogueId).then(
                response => {
                    commit('getMessagesSuccess', response.data.data);
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        sendMessage({ commit }, data) {
            return UserService.sendMessage(data).then(
                response => {
                    commit('sendMessageSuccess', response.data.data.message);
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('sendMessageFailure');
                    return Promise.reject(error);
                }
            );
        },
        getDialogueData({ commit }, data) {
            i18n.global.locale = localStorage.getItem('lang') || 'ru';
            
            return UserService.getDialogueData(data).then(
                response => {
                    if (response.data.status === false && response.data.code === 404) {
                        commit('clearDialogueData');
                        return Promise.resolve(response.data);
                    }
                    commit('storeDialogueDataSuccess', response.data.data);
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('storeDialogueDataFailure');
                    return Promise.reject(error);
                }
            );
        },
        storeMessage({ commit }, data) {
            if (data.data.message.dialogue_id == data.dialogueId) {
                commit('storeMessageSuccess', data.data.message);
            }
        },
    },
    mutations: {
        getMessagesSuccess(state, messages) {
            state.messages = messages;
            state.messages.push({
                message: i18n.global.t('ask_question_online'),
                direction: 'out',
                integration_created_at: moment().unix()
            })
        },
        sendMessageSuccess(state, message) {
            state.messages.push(message);
        },
        storeMessageSuccess(state, message) {
            const messageExists = state.messages.some(el => el.integration_message_id === message.integration_message_id)
            if (!messageExists && message.direction === 'out') {
                state.messages.push(message);
            }
        },
        storeDialogueDataSuccess(state, data) {
            state.chatbotId = data.chatbot_id;
            state.dialogueId = data.dialogue_id;
        },
        clearDialogueData(state) {
            state.dialogueId = null;
        }
    }
};
